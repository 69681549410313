<template>
    <div class="item">
        <div class="ui icon input">
        <input type="text" placeholder="Busqueda..." v-model="textobusqueda">
        <i class="search link icon" @click="busca()"></i>
        <!-- <i class="plus icon" style="left: auto; right: 1px;" @click="amplia()"></i> -->
        </div>
    </div>
</template>

<script>
const Api = require("../packs/api");
import { EventBus } from "../packs/eventbus.js";

export default {
  props: {
    id: {
      type: Number,
      default: -1
    },
    documento: {
      type: String,
      default: "factura"
    },
  },
  data: function() {
    return {
      cliente: -1,
      textobusqueda: '',
      elemento_busqueda: ''
    };
  },
  created() {
    // EventBus.$on("enqueformularioestoy", data => {
    //     this.elemento_busqueda = data;
    //     console.log('Me he enterado!!!!');
    // //   this.recalculaTotales(data);
    // });

  },
  mounted() {
    console.log('--- Desde busqueda -> documento: ', this.documento);
  },
  methods: {
    busca: function() {
      if (this.textobusqueda !== '') {
        switch (this.documento) {
          case 'facturas':
            window.location = '/facturas?numero=' + this.textobusqueda;
            // console.log('--- Voy a consultar ' + this.documento);⁄
            break;
          case 'facturasm':
            window.location = '/facturasm?numero=' + this.textobusqueda;
            // console.log('--- Voy a consultar ' + this.documento);⁄
            break;
          case 'albaranes':
            window.location = '/albaranes?numero=' + this.textobusqueda;
            // console.log('--- Voy a consultar ' + this.documento);
            break;
          case 'albaranesm':
            window.location = '/albaranesm?numero=' + this.textobusqueda;
            // console.log('--- Voy a consultar ' + this.documento);⁄
            break;
          case 'presupuestos':
            window.location = '/presupuestos?numero=' + this.textobusqueda;
            break;
          case 'presupuestosm':
            window.location = '/presupuestosm?numero=' + this.textobusqueda;
            break;
          case 'entregas':
            window.location = '/entregas?numero=' + this.textobusqueda;
            break;
          case 'clientesm':
            window.location = '/clientesm?numero=' + this.textobusqueda;
            break;
          case 'articulosm':
            window.location = '/articulosm?numero=' + this.textobusqueda;
            break;
          default:
            break;
        }
        // console.log('---- busqueda ----', this.textobusqueda);
      } else {
        switch (this.documento) {
          case 'articulosm':
            window.location = '/articulosm';
            break;
        }
      }
    },
    amplia: function() {
        console.log('---- amplia ----');
    },
  },
  beforeMount() {
      // this.listTasks("2");
  },
  computed: {
      }
};
</script>
