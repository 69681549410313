import Vue from 'vue'
import Busquedavue from '../components/busqueda.vue'

document.addEventListener('DOMContentLoaded', () => {

    const busquedavue = new Vue({
        el: '[data-behavior="busqueda"]',
        components: {
            Busquedavue
        }
    });
})